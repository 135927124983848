import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import iassign from 'immutable-assign';

import { ImmutableSelector } from '@ultra/core/helpers';

import { UniqFactoriesRecommendation } from '../uniq-factories-recommendation.interface';

import * as UniqFactoriesRecommendationActions from './uniq-factories-recommendation.actions';

const INITIAL_STATE: UniqFactoriesRecommendation = {
  uniqFactories: [],
  title: '',
};

@State<UniqFactoriesRecommendation>({
  name: 'uniqFactoriesRecommendation',
  defaults: INITIAL_STATE,
})
@Injectable()
export class UniqFactoriesRecommendationState {
  @Selector()
  @ImmutableSelector()
  public static getRecommendedUniqFactories(state: UniqFactoriesRecommendation) {
    return state.uniqFactories;
  }

  @Selector()
  @ImmutableSelector()
  public static getRecommendedTitle(state: UniqFactoriesRecommendation) {
    return state.title;
  }

  @Action(UniqFactoriesRecommendationActions.SetStaticCollection)
  public setUniqFactories(
    { setState }: StateContext<UniqFactoriesRecommendation>,
    { payload }: UniqFactoriesRecommendationActions.SetStaticCollection
  ) {
    setState(payload);
  }

  @Action(UniqFactoriesRecommendationActions.UpdateUniqFactories)
  public updateUniqFactories(
    { setState, getState }: StateContext<UniqFactoriesRecommendation>,
    { payload }: UniqFactoriesRecommendationActions.UpdateUniqFactories
  ) {
    const newState = iassign(
      getState(),
      (state) => state.uniqFactories,
      (uniqFactories) => {
        if (payload.length === 0) {
          return uniqFactories;
        }
        return payload;
      }
    );
    setState(newState);
  }

  @Action(UniqFactoriesRecommendationActions.UpdateTitle)
  public updateTitle(
    { setState, getState }: StateContext<UniqFactoriesRecommendation>,
    { payload }: UniqFactoriesRecommendationActions.UpdateTitle
  ) {
    const newState = iassign(
      getState(),
      (state) => state.title,
      () => payload
    );
    setState(newState);
  }
}

import { IUniqFactory } from '../../../../uniq-factory/uniq-factory.interface';
import { UniqFactoriesRecommendation } from '../uniq-factories-recommendation.interface';

export class SetStaticCollection {
  public static type = '[Uniq Factories Recommendation] Set static collection';

  constructor(public payload: UniqFactoriesRecommendation) {}
}

export class UpdateUniqFactories {
  public static type = '[Uniq Factories Recommendation] Update Uniq Factories recommendation';
  constructor(public payload: IUniqFactory[]) {}
}

export class UpdateTitle {
  public static type = '[Uniq Factories Recommendation] Update title recommendation';
  constructor(public payload: string) {}
}
